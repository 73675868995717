import React from 'react';
import LoginButton from './LoginButton';
import Box from '@material-ui/core/Box';

const Login = ({ onLogin = () => {} }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      style={{ height: '100vh' }}
    >
      <img
        src="https://res.cloudinary.com/sandboxvrcdn/image/upload/v1566553567/web/images/sandboxvr_dvd4wz.png"
        style={{ width: '70vw' }}
        alt="sandboxvr logo"
      />
      <LoginButton onLogin={onLogin} />
    </Box>
  );
};

export default Login;
