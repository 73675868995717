import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Team from './Team';

const Queue = props => {
  const { events } = props;

  return (
    <Box key="bench" display="flex" flexDirection="column">
      {events.map(event => (
        <Team key={`team-${event.id}`} event={event} />
      ))}
    </Box>
  );
};

Queue.propTypes = {
  events: PropTypes.array.isRequired,
};

export default Queue;
