import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Space from './Space';

const Spaces = props => {
  const [expanded, setExpanded] = useState(null);
  const { data, events, eventField, type } = props;

  const handleExpand = spaceId => event => {
    event.stopPropagation();
    setExpanded(expanded === spaceId ? null : spaceId);
  };

  return (
    <Box key="bench" display="flex" flexDirection="column">
      {data.map((space, index) => (
        <Space
          type={type}
          key={space.id}
          data={space}
          event={events.find(event => event[eventField] === index + 1)}
          onClickExpand={handleExpand(space.id)}
          expanded={expanded === space.id}
        />
      ))}
    </Box>
  );
};

Spaces.propTypes = {
  data: PropTypes.array.isRequired,
  events: PropTypes.array.isRequired,
};

export default Spaces;
