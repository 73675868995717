import React, { useEffect } from 'react';
import useAuth from 'hooks/useAuth';
import { useParams } from 'react-router-dom';

const STORE_URL = `${process.env.REACT_APP_SANDBOXVR_STORE_BASE_URL}/store_info`;
const DEVICES_URL = `${process.env.REACT_APP_SANDBOXVR_TV_BASE_URL}/auth/devices`;

const fetchStore = async location => {
  const result = await fetch(`${STORE_URL}?location=${location}`);
  if (!result.ok) {
    const text = await result.text();
    throw new Error(`Fetch Store Error: ${text}`);
  }
  const json = await result.json();
  return json;
};

export const fetchDevice = async (location, token) => {
  const result = await fetch(DEVICES_URL, {
    method: 'POST',
    headers: {
      authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ location }),
  });
  if (!result.ok) {
    const text = await result.text();
    throw new Error(`Fetch Devices Error: ${text}`);
  }
  const json = await result.json();
  return json;
};

let storeInfo = {};
let storeError = null;
let fetchPromise = {};
const useStoreInfo = (location, token) => {
  useEffect(() => {
    if (!token) return;
    if (storeInfo[location]) return;
    if (fetchPromise[location]) return;
    fetchPromise[location] = Promise.all([fetchStore(location), fetchDevice(location, token)])
      .then(([store, devices]) => {
        storeInfo[location] = {
          store,
          devices,
        };
      })
      .catch(x => {
        storeError = x;
      });
  }, [location, token]);
  if (storeInfo[location]) return storeInfo[location];
  if (storeError) throw storeError;
  if (fetchPromise[location]) throw fetchPromise[location];

  return { store: { rooms: [] }, devices: [] };
};

const withStoreInfo = WrappedComponent => {
  const C = props => {
    const { location } = useParams();
    const { token } = useAuth();
    const storeInfo = useStoreInfo(location, token);
    return <WrappedComponent {...props} storeInfo={storeInfo} />;
  };
  C.displayName = `withStoreInfo(${WrappedComponent.displayName || WrappedComponent.name})`;
  return C;
};

export { useStoreInfo, withStoreInfo };
