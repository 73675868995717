import React from 'react';
import Login from './Login';
import useAuth from 'hooks/useAuth';

const AuthWrapper = ({ children }) => {
  const { user, isInited } = useAuth();
  if (!isInited) return null;
  return <>{user ? children : <Login />}</>;
};
export default AuthWrapper;
