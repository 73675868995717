import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { createHttpLink } from 'apollo-link-http';

import { AuthStore } from 'hooks/useAuth';

export default new ApolloClient({
  link: setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${AuthStore.getState().token}`,
      'apollographql-client-name': 'ControlCenter',
      'apollographql-client-version': process.env.REACT_APP_VERSION,
    },
  })).concat(createHttpLink({ uri: process.env.REACT_APP_SANDBOXVR_GRAPHQL })),
  cache: new InMemoryCache(),
});
