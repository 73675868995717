import React, { useCallback } from 'react';
import { auth } from 'firebase/app';
import Button from '@material-ui/core/Button';

const Login = ({ onLogin, persistence }) => {
  const doLogin = useCallback(async () => {
    if (persistence) {
      await auth().setPersistence(auth.Auth.Persistence.LOCAL);
    }
    try {
      const result = await auth().signInWithPopup(new auth.GoogleAuthProvider());
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = result && result.credential;
      // The signed-in user info.
      const user = result.user;
      if (user && credential) {
        onLogin(user, credential);
      }
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      const email = error.email;
      // The firebase.auth.AuthCredential type that was used.
      const credential = error.credential;
      console.error(errorCode, errorMessage, email, credential);
    }
  }, [onLogin, persistence]);
  return (
    <Button variant="outlined" onClick={doLogin}>
      Sign in with Google
    </Button>
  );
};
export default Login;
