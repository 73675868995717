import { DateTime } from 'luxon';

const timestampToTime = timestamp => {
  const options = {
    formatMatcher: 'best fit',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const date = DateTime.fromMillis(Number(timestamp));
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const timestampToDate = timestamp => {
  const options = { month: 'short', day: 'numeric' };
  const date = DateTime.fromMillis(Number(timestamp));
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

const timestampToDateTime = timestamp => {
  const options = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  };
  const date = DateTime.fromMillis(Number(timestamp));
  return new Intl.DateTimeFormat('en-US', options).format(date);
};

export default { timestampToTime, timestampToDate, timestampToDateTime };
export { timestampToTime, timestampToDate, timestampToDateTime };
