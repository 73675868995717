import React from 'react';
import { Box, Typography, Tabs, Tab, createStyles, withStyles } from '@material-ui/core';
import {
  Room as RoomIcon,
  DevicesOther as DevicesOtherIcon,
  ArrowBack as ArrowBackIcon,
} from '@material-ui/icons';
import { Link, withRouter, Switch, Route, Redirect } from 'react-router-dom';
import { flow, last } from 'lodash';

import RoomRoute from './RoomRoute';
import DeviceRoute from './DeviceRoute';

const styles = createStyles(theme => ({
  flexContainer: {
    justifyContent: 'center',
  },
  backButton: {
    color: 'black',
    cursor: 'pointer',
    zIndex: 1,
  },
  header: {
    boxSizing: 'border-box',
  },
  title: {},
}));
// interface Props extends WithStyles<typeof styles> {
// }
class StreamingPage extends React.Component {
  onTabChange = (e, value) => {
    const { match, history } = this.props;
    history.push(`${match.url}/${value}`);
  };

  render() {
    const { classes, match, location } = this.props;
    const {
      params: { location: loc },
    } = match;
    const deviceType = last(location.pathname.split('/'));

    return (
      <Box display="flex" flexDirection="column" height="100%">
        <Box
          textAlign="center"
          width="100%"
          display="flex"
          alignItems="center"
          p={2}
          className={classes.header}
        >
          <Link className={classes.backButton} to={`/${loc}`}>
            <ArrowBackIcon fill="black" width="48" height="48" />
          </Link>
          <Box flex="1" textAlign="center">
            <Typography variant="h4" className={classes.title}>
              Streaming
            </Typography>
          </Box>
        </Box>
        <Box>
          <Tabs
            value={deviceType === 'device' ? 'device' : 'room'}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.onTabChange}
            classes={{ flexContainer: classes.flexContainer }}
          >
            <Tab icon={<RoomIcon />} label="Rooms" value="room" />
            <Tab icon={<DevicesOtherIcon />} label="Devices" value="device" />
          </Tabs>
        </Box>
        <Box flex="1" position="relative" overflow="hidden">
          <Box height="100%" overflow="auto">
            <Switch>
              <Route exact path={`${match.path}/room`} component={RoomRoute} />
              <Route exact path={`${match.path}/device`} component={DeviceRoute} />
              <Route>
                <Redirect to={`${match.url}/room`} />
              </Route>
            </Switch>
          </Box>
        </Box>
      </Box>
    );
  }
}

export default flow([withStyles(styles), withRouter])(StreamingPage);
