import React, { useRef, useState, useCallback } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Menu,
  makeStyles,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import TV from 'static/images/tv.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 300,
    height: 80,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    cursor: ({ onClick }) => (Boolean(onClick) ? 'pointer' : 'default'),
  },
  thumbnail: {
    width: 80,
    backgroundSize: 'contain',
  },
  content: {
    flex: 1,
    textAlign: 'left',
  },
  title: {
    maxWidth: ({ onStopClick }) => (Boolean(onStopClick) ? 150 : 200),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  subTitle: {
    display: 'block',
    maxWidth: ({ onStopClick }) => (Boolean(onStopClick) ? 150 : 200),
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '0.6rem',
  },
  description: {
    display: 'block',
    maxWidth: 150,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    fontSize: '0.6rem',
  },
  moreButton: {
    display: 'block',
  },
  stopButton: {
    color: 'red',
  },
}));

// interface Props {
//   id: string;
//   title: string;
//   subTitle: string;
//   description: string;
//   onStopClick?: (deviceId: string) => void;
//   onClick?: (deviceId: string) => void;
// }
const DeviceCard = ({ id, title, subTitle, description, onStopClick, onClick }) => {
  const main = title || subTitle;

  const styles = useStyles({ onClick, onStopClick });
  const anchorEl = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const handleStopClick = useCallback(() => {
    close();
    onStopClick(id);
  }, [close, id, onStopClick]);
  const handleClick = useCallback(() => {
    onClick && onClick(id);
  }, [onClick, id]);

  return (
    <Card className={styles.root} onClick={handleClick}>
      <CardMedia className={styles.thumbnail} image={TV} title="Empty TV" />
      <CardContent className={styles.content}>
        <Typography variant="h6" className={styles.title}>
          {main}
        </Typography>
        {title && subTitle && (
          <Typography variant="caption" className={styles.subTitle}>
            {subTitle}
          </Typography>
        )}
        {description && (
          <Typography variant="caption" className={styles.description}>
            {description}
          </Typography>
        )}
      </CardContent>
      {onStopClick && (
        <Box>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            size="small"
            ref={anchorEl}
            onClick={open}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl.current} open={isOpen} onClose={close}>
            <MenuItem onClick={handleStopClick}>
              <Typography variant="body1" className={styles.stopButton}>
                Stop playing
              </Typography>
            </MenuItem>
          </Menu>
        </Box>
      )}
    </Card>
  );
};

export default DeviceCard;
