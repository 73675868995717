import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  DialogContent,
  Typography,
  DialogTitle,
  Dialog,
  Box,
  ButtonGroup,
  Button,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  CircularProgress,
} from '@material-ui/core';
import { timestampToTime as time, timestampToDate as date } from '../time.js';
import { isQueueStatus, getEventStatus } from '../helper/helper';
import { sortBy } from 'lodash';

const DELETE_MEMBERS = gql`
  mutation checkOut($eventId: String!, $minors: [String]!, $guests: [String]!) {
    checkOut(eventId: $eventId, minors: $minors, guests: $guests) {
      id
      checkedIn {
        guests {
          id
          firstName
          lastName
          email
        }
        minors {
          id
          name
        }
      }
    }
  }
`;

const GET_AN_EVENT = gql`
  query event($id: ID!) {
    event(id: $id) {
      checkedIn {
        guests {
          id
          firstName
          lastName
          email
        }
        minors {
          id
          name
        }
      }
    }
  }
`;

const REASSIGN_MEMBERS = gql`
  mutation reassignPlayers(
    $checkOutEventId: String!
    $checkInEventId: String!
    $guests: [String]!
    $minors: [String]!
  ) {
    reassignPlayers(
      checkOutEventId: $checkOutEventId
      checkInEventId: $checkInEventId
      guests: $guests
      minors: $minors
    ) {
      id
      checkedIn {
        guests {
          id
          email
          firstName
          lastName
        }
        minors {
          id
          name
        }
      }
    }
  }
`;

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    fontWeight: 900,
    fontSize: '2em',
  },
  title: {
    fontWeight: 600,
  },
}));

const MovePanel = props => {
  const { area, onClick, type } = props;
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Typography variant="overline">{type}</Typography>
      <ButtonGroup size="small">
        {area.map(space => {
          return (
            <Button key={`button-${space}`} aria-label="Delete" onClick={onClick(space, type)}>
              {space}
            </Button>
          );
        })}
      </ButtonGroup>
    </Box>
  );
};

MovePanel.propTypes = {
  area: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

const TeamDetailDialog = props => {
  const [members, setMembers] = useState([]);
  const [checkedItems, setCheckedItems] = useState({});
  const [selectedTargetEvent, setSelectedTargetEvent] = useState('');
  const [loading, setLoading] = React.useState(false);
  const { detail, onClose, availableBarracks, availableRooms, assignTeam, client, events } = props;
  const {
    id,
    purchasedRoom,
    startDate,
    endDate,
    checkedIn,
    open,
    assignedRoom,
    assignedBarrack,
    checkfrontBookingId,
    experience,
    host,
    linkedEvents,
  } = detail;

  const classes = useStyles();

  const onMyClose = () => {
    setMembers([]);
    setCheckedItems({});
    setLoading(false);
    onClose();
  };

  const { spaceType, spaceNumber } = getEventStatus({
    assignedBarrack,
    assignedRoom,
  });

  const handleClick = (spaceId, spaceType) => async () => {
    setLoading(prevLoading => !prevLoading);
    await assignTeam(
      {
        id,
        type: 'group',
      },
      spaceId,
      spaceType,
    );
    onMyClose();
  };

  const handleChange = member => event => {
    setCheckedItems({
      ...checkedItems,
      [member.id]: event.target.checked,
    });
  };

  const handleMemberClick = async () => {
    if (
      !window.confirm(
        `update ${JSON.stringify(
          members.filter(member => checkedItems[member.id]).map(member => member.firstName),
        )}?`,
      )
    ) {
      throw new Error('Action cancelled');
    }
    const memberLists = members.filter(m => checkedItems[m.id]);
    const defaultVariables = {
      minors: memberLists.filter(m => m.__typename === 'Minor').map(m => m.id),
      guests: memberLists.filter(m => m.__typename === 'Guest').map(m => m.id),
    };

    const REASSIGN = 'REASSIGN';
    const UNASSIGN = 'UNASSIGN';
    const aType = selectedTargetEvent ? REASSIGN : UNASSIGN;
    const reducer = type => {
      switch (type) {
        case REASSIGN:
          return {
            variables: {
              ...defaultVariables,
              checkOutEventId: id,
              checkInEventId: selectedTargetEvent,
            },
            mutation: REASSIGN_MEMBERS,
          };
        case UNASSIGN:
          return {
            variables: {
              ...defaultVariables,
              eventId: id,
            },
            mutation: DELETE_MEMBERS,
          };
        default:
          throw new Error(`invalid action type ${type}`);
      }
    };
    const { variables, mutation } = reducer(aType);
    const mutationResponse = await client.mutate({
      variables,
      mutation,
    });
    setCheckedItems({});
    console.log({ aType });
    const players =
      aType === REASSIGN
        ? (await client.query({ variables: { id }, query: GET_AN_EVENT })).data.event
        : mutationResponse.data.checkOut;
    setMembers(
      sortBy(
        [
          ...players.checkedIn.guests,
          ...players.checkedIn.minors.map(minor => {
            return {
              __typename: minor.__typename,
              id: minor.id,
              firstName: minor.name,
              lastName: '',
            };
          }),
        ],
        ['firstName', 'lastName'],
      ),
    );
  };

  useEffect(() => {
    if (!open && !checkedIn && members.length > 0) {
      setMembers([]);
      setCheckedItems({});
    }

    if (open && checkedIn && members.length === 0) {
      const newMembers = [
        ...members,
        ...checkedIn.guests,
        ...checkedIn.minors.map(minor => {
          return {
            __typename: minor.__typename,
            id: minor.id,
            firstName: minor.name,
            lastName: '',
            checkedInAt: minor.checkedInAt,
          };
        }),
      ];

      const sortedMembers = sortBy(newMembers, ['checkedInAt', 'firstName', 'lastName']);
      setMembers(sortedMembers);
      setSelectedTargetEvent('');
    }
  }, [open]);

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  };

  const cancelTitleStyle = {
    fontSize: '1.0rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  };

  var title = <div></div>;
  if (linkedEvents && linkedEvents.length > 0) {
    title = (
      <div>
        <Typography style={titleStyle}>{checkfrontBookingId}</Typography>
        {linkedEvents.map((e, index) =>
          e.status === 'cancelled' ? (
            <Typography key={index} color="error" style={cancelTitleStyle}>
              {e.checkfrontBookingId}(cancelled)
            </Typography>
          ) : (
            <Typography key={index} style={titleStyle}>
              {e.checkfrontBookingId}
            </Typography>
          ),
        )}
      </div>
    );
  } else {
    title = (
      <div>
        <Typography style={titleStyle}>{checkfrontBookingId}AAA</Typography>
      </div>
    );
  }
  return (
    <section>
      {open && (
        <Dialog
          onClose={onMyClose}
          aria-labelledby="simple-dialog-title"
          open={open}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle id="simple-dialog-title" className={classes.dialogTitle} disableTypography>
            {`${spaceType} ${spaceNumber}`}
          </DialogTitle>
          <DialogContent>
            <Typography variant="h5" component="h2">
              {checkfrontBookingId}
            </Typography>
            {linkedEvents &&
              linkedEvents.length > 0 &&
              linkedEvents.map(e =>
                e.status === 'cancelled' ? (
                  <Typography color="error" style={cancelTitleStyle}>
                    {e.checkfrontBookingId}(cancelled)
                  </Typography>
                ) : (
                  <Typography
                    variant="h5"
                    component="h2"
                    key={e.checkfrontBookingId}
                    style={titleStyle}
                  >
                    {e.checkfrontBookingId}
                  </Typography>
                ),
              )}
            <Typography>
              <Typography component="span" className={classes.title}>
                Reserved Room:{' '}
              </Typography>
              {`${purchasedRoom}`}
            </Typography>
            {host && (
              <Typography>
                <Typography component="span" className={classes.title}>
                  Email:{' '}
                </Typography>
                {`${host.email}`}
              </Typography>
            )}
            <Typography>
              <Typography component="span" className={classes.title}>
                Booking Date:{' '}
              </Typography>
              {`${date(startDate)}, ${time(startDate)} - ${time(endDate)}`}
            </Typography>
            <Typography>
              <Typography component="span" className={classes.title}>
                Reserved Experience:{' '}
              </Typography>
              {experience && experience.title}
            </Typography>
            <Typography>
              <Typography component="span" className={classes.title}>
                Number of Checked-in Guests:{' '}
              </Typography>
              {members && members.length}
            </Typography>
            {loading && (
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <CircularProgress />
                <Typography>Saving...</Typography>
                <Typography>Please do not close this window</Typography>
              </Box>
            )}
            {!loading && (
              <section>
                {members && members.length > 0 && (
                  <Box display="flex" justifyContent="space-around" p={2}>
                    <Box display="flex" flexDirection="column">
                      {members
                        .filter(member => !checkedItems[member.id])
                        .map(member => (
                          <FormControlLabel
                            key={member.id}
                            control={
                              <Checkbox
                                checked={!!checkedItems[member.id]}
                                onChange={handleChange(member)}
                                inputProps={{
                                  'aria-label': 'primary checkbox',
                                }}
                                value={member.id}
                              />
                            }
                            label={`${member.firstName} ${member.lastName}`}
                          />
                        ))}
                    </Box>
                    {Object.values(checkedItems).includes(true) && (
                      <Box>
                        <FormControl className={classes.formControl}>
                          <Select
                            value={selectedTargetEvent}
                            onChange={event => setSelectedTargetEvent(event.target.value)}
                            displayEmpty
                          >
                            <MenuItem key="unassigned" value="">
                              <em>UNASSIGNED</em>
                            </MenuItem>
                            {events
                              .filter(
                                event =>
                                  event.checkedIn.guests.length > 0 ||
                                  event.checkedIn.minors.length > 0,
                              )
                              .filter(event => event.id !== detail.id)
                              .map(event => {
                                const { firstName, lastName } = event.host;
                                return (
                                  <MenuItem key={event.id} value={event.id}>
                                    {`${firstName} ${lastName}`}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                        <Box display="flex" flexDirection="column">
                          {members
                            .filter(member => checkedItems[member.id])
                            .map(member => (
                              <FormControlLabel
                                key={member.id}
                                control={
                                  <Checkbox
                                    color="primary"
                                    checked={!!checkedItems[member.id]}
                                    onChange={handleChange(member)}
                                    inputProps={{
                                      'aria-label': 'primary checkbox',
                                    }}
                                    value={member.id}
                                  />
                                }
                                label={`${member.firstName} ${member.lastName}`}
                              />
                            ))}
                        </Box>
                        <Button variant="contained" color="primary" onClick={handleMemberClick}>
                          Update Members
                        </Button>
                      </Box>
                    )}
                  </Box>
                )}
                <Box>
                  {!isQueueStatus(detail) && (
                    <MovePanel area={['UNASSIGNED']} onClick={handleClick} type="queue" />
                  )}
                  <MovePanel area={availableBarracks} onClick={handleClick} type="barrack" />
                  {!isQueueStatus(detail) && (
                    <MovePanel area={availableRooms} onClick={handleClick} type="room" />
                  )}
                </Box>
              </section>
            )}
          </DialogContent>
        </Dialog>
      )}
    </section>
  );
};

TeamDetailDialog.propTypes = {
  assignTeam: PropTypes.func.isRequired,
  availableBarracks: PropTypes.array.isRequired,
  availableRooms: PropTypes.array.isRequired,
  detail: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TeamDetailDialog;
