import React from 'react';

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(e) {
    return { error: e };
  }

  render() {
    const { children, renderError } = this.props;
    const { error } = this.state;
    if (error) {
      return renderError(error);
    }
    return children;
  }
}

export default ErrorBoundary;
