import React from 'react';
import { Box, Typography, Drawer, createStyles, withStyles, IconButton } from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import { flow, get, sortBy } from 'lodash';
import * as firebase from 'firebase/app';

import { withStoreInfo } from 'hooks/useStoreInfo';
import { withAuth } from 'hooks/useAuth';

import RoomCard from './components/RoomCard';
import DeviceCard from './components/DeviceCard';

const CLOUD_STORE_NAME = 'operations-platform-app';
const { REACT_APP_SANDBOXVR_TV_BASE_URL } = process.env;

const DEVICE_MODE_RECAP = 'recap';

const STATUS_STARTING = 'STARTING';
const STATUS_STREAMING = 'STREAMING';
const STATUS_STOPPING = 'STOPPING';

const styles = createStyles(theme => ({
  subTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  beforeDivider: {
    width: theme.spacing(2),
  },
  divider: {
    flex: 1,
  },
  drawerTitle: {
    fontWeight: 'bold',
  },
  drawerSubTitle: {
    fontWeight: '500',
    paddingRight: theme.spacing(4),
  },
  drawerDivider: {
    flex: 1,
  },
}));
// interface Props extends WithStyles<typeof styles> {
// }

class RoomRoute extends React.Component {
  state = {
    selectedRoomId: '',
    isOpen: false,
  };

  componentDidMount() {
    // on redirect
    const { storeInfo, location } = this.props;
    const number = new URLSearchParams(location.search).get('roomNumber');
    const roomNumber = parseInt(number, 10);
    const selectedRoom = storeInfo.store.rooms.find(x => x.roomNumber === roomNumber);
    if (selectedRoom) {
      this.setState({ selectedRoomId: selectedRoom.roomId, isOpen: true });
    }
  }

  componentDidUpdate(lastProps) {
    // on direct url input
    const { storeInfo, location } = this.props;
    if (lastProps.storeInfo.store.rooms.length === 0 && storeInfo.store.rooms.length !== 0) {
      const number = new URLSearchParams(location.search).get('roomNumber');
      const roomNumber = parseInt(number, 10);
      const selectedRoom = storeInfo.store.rooms.find(x => x.roomNumber === roomNumber);
      if (selectedRoom) {
        this.setState({ selectedRoomId: selectedRoom.roomId, isOpen: true });
      }
    }
  }

  handleStopBoardCastClick = async roomId => {
    const {
      match: {
        params: { location },
      },
      auth: { token },
      storeInfo: { store, devices },
    } = this.props;
    const db = firebase.app(CLOUD_STORE_NAME).firestore();
    const room = store.rooms.find(x => x.roomId === roomId);

    db.collection('stores')
      .doc(location)
      .collection('rooms')
      .doc(String(room.roomNumber))
      .set({
        status: STATUS_STOPPING,
      });

    const connectedDevices = devices
      .filter(x => get(x, 'config.deviceMode', '').toLowerCase() === DEVICE_MODE_RECAP)
      .filter(x => get(x, 'config.active', false))
      .filter(x => get(x, 'config.roomNumber', '') === String(room.roomNumber));
    connectedDevices.forEach(d =>
      fetch(`${REACT_APP_SANDBOXVR_TV_BASE_URL}/auth/reset`, {
        method: 'POST',
        body: JSON.stringify({ deviceId: d.config.serial }),
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }),
    );
  };

  handleSwitchDeviceClick = roomId => {
    this.setState({ selectedRoomId: roomId, isOpen: true });
  };

  closeDrawer = () => {
    this.setState({ selectedRoomId: '', isOpen: false });
  };

  handleStreamingClick = deviceId => {
    const { selectedRoomId } = this.state;
    const {
      match: {
        params: { location },
      },
      storeInfo: { store },
    } = this.props;
    const db = firebase.app(CLOUD_STORE_NAME).firestore();
    const room = store.rooms.find(x => x.roomId === selectedRoomId);

    db.collection('stores')
      .doc(location)
      .collection('rooms')
      .doc(String(room.roomNumber))
      .set({
        status: STATUS_STARTING,
      });
    db.collection('stores')
      .doc(location)
      .collection('rooms')
      .doc(String(room.roomNumber))
      .onSnapshot(async doc => {
        const { livestreamUrl, status } = doc.data();
        if (status === STATUS_STREAMING) {
          await fetch(`${REACT_APP_SANDBOXVR_TV_BASE_URL}/recap/showLiveStream`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              livestreamUrl,
              deviceId: deviceId,
            }),
          });
        }
      });
    this.closeDrawer();
  };

  render() {
    const { classes, storeInfo } = this.props;
    const { isOpen, selectedRoomId } = this.state;
    const recapDevices = storeInfo.devices
      .filter(x => get(x, 'config.deviceMode', '').toLowerCase() === DEVICE_MODE_RECAP)
      .filter(x => get(x, 'config.active', false))
      .filter(x => !get(x, 'config.developerDevice', false));

    const selectedRoom = storeInfo.store.rooms.find(x => x.roomId === selectedRoomId);

    return (
      <React.Fragment>
        <Box pt={2} pb={8}>
          <Box textAlign="left" px={2} display="flex" alignItems="center">
            <hr className={classes.beforeDivider} />
            <Typography variant="h6" className={classes.subTitle}>
              ROOM
            </Typography>
            <hr className={classes.divider} />
          </Box>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            {sortBy(storeInfo.store.rooms, ['roomNumber']).map(r => (
              <RoomCard
                key={r.roomId}
                id={r.roomId}
                title={`Room ${r.roomNumber}`}
                onStopClick={this.handleStopBoardCastClick}
                onStreamClick={this.handleSwitchDeviceClick}
              />
            ))}
          </Box>
        </Box>
        <Drawer anchor="bottom" open={isOpen} onClose={this.closeDrawer}>
          <Box display="flex" flexDirection="column" p={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center" pl={2}>
              <Typography variant="h4" className={classes.drawerTitle}>
                Stream to TV
              </Typography>
              <IconButton onClick={this.closeDrawer}>
                <CloseIcon />
              </IconButton>
            </Box>
            <Box>
              {selectedRoom && (
                <RoomCard id={selectedRoom.roomId} title={`Room ${selectedRoom.roomNumber}`} />
              )}
            </Box>
            <Box display="flex" alignItems="center" px={2}>
              <Typography variant="h6" className={classes.drawerSubTitle}>
                Select TV
              </Typography>
              <hr className={classes.drawerDivider} />
            </Box>
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              maxHeight="70vh"
              overflow="auto"
            >
              {sortBy(recapDevices, [
                'config.roomNumber',
                'config.displayName',
                'config.serial',
              ]).map(d => (
                <DeviceCard
                  key={d.config.serial}
                  title={d.config.displayName}
                  subTitle={d.config.serial}
                  id={d.config.serial}
                  onClick={this.handleStreamingClick}
                />
              ))}
            </Box>
          </Box>
        </Drawer>
      </React.Fragment>
    );
  }
}

export default flow([withStyles(styles), withAuth, withStoreInfo, withRouter])(RoomRoute);
