import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { auth, database } from 'firebase/app';

class SimpleStore {
  state = {};
  listener = [];

  constructor(state = {}) {
    this.state = state;
  }

  getState = () => {
    return this.state;
  };
  sub = fn => {
    this.listener.push(fn);
    return () => {
      this.listener = this.listener.filter(x => x !== fn);
    };
  };
  setState = state => {
    this.state = { ...this.state, ...state };
    this.listener.forEach(fn => fn(this.state));
  };
}

const AuthStore = new SimpleStore({
  user: null,
  token: null,
  isInited: false,
});
const AuthContext = createContext(AuthStore);
const AuthProvider = ({ children }) => {
  const firebaseAuth = auth();
  let lastRef = null;
  // firebase by default use indexDB in web which only allow async operation
  // referencing react-redux-firebase init method
  // https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/auth.js#L1022
  // https://github.com/firebase/firebase-js-sdk/blob/master/packages/auth/src/storageusermanager.js#L210

  firebaseAuth.onAuthStateChanged(async user => {
    AuthStore.setState({ user, isInited: true, token: null });
    if (lastRef) lastRef.off('value');
    if (user) {
      const result = await user.getIdTokenResult();
      AuthStore.setState({ token: result.token });
      lastRef = database().ref(`metadata/${user.uid}/refreshTime`);
      lastRef.on('value', async () => {
        // refresh token
        const t = await user.getIdTokenResult(true);
        AuthStore.setState({ token: t.token });
      });
    }
  });
  return <AuthContext.Provider value={AuthStore}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const [, forceUpdate] = useReducer(s => s + 1, 0);
  const AuthStore = useContext(AuthContext);
  useEffect(() => {
    return AuthStore.sub(() => {
      forceUpdate({});
    });
  });
  return AuthStore.getState();
};

const withAuth = WrappedComponent => {
  const C = props => {
    const auth = useAuth();
    return <WrappedComponent {...props} auth={auth} />;
  };
  C.displayName = `withAuth(${WrappedComponent.displayName || WrappedComponent.name})`;
  return C;
};

export { useAuth as default, AuthProvider as Provider, AuthStore, withAuth };
