import { useState } from 'react';

const useTeamDetail = () => {
  const [detail, setDetail] = useState({
    open: false,
    member: [],
  });

  const set = data => {
    setDetail({
      ...data,
      open: true,
    });
  };

  const clear = () =>
    setDetail({
      open: false,
      member: [],
    });

  return [detail, set, clear];
};

export default useTeamDetail;
