import React, { useRef, useState, useCallback } from 'react';
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  IconButton,
  Box,
  MenuItem,
  Menu,
  makeStyles,
} from '@material-ui/core';
import { MoreVert as MoreVertIcon } from '@material-ui/icons';

import Cube from 'static/images/cube.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: 300,
    height: 80,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  thumbnail: {
    width: 80,
    backgroundSize: 'contain',
  },
  content: {
    flex: 1,
    textAlign: 'left',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '1rem',
  },
  moreButton: {
    display: 'block',
  },
  stopButton: {
    color: 'red',
  },
}));

// interface Props {
//   id: string;
//   title: string;
//   onStopClick: (roomId: string) => void;
//   onStreamClick: (roomId: string) => void;
// }
const RoomCard = ({ id, title, onStopClick, onStreamClick }) => {
  const styles = useStyles();
  const anchorEl = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const open = useCallback(() => {
    setOpen(true);
  }, [setOpen]);
  const close = useCallback(() => {
    setOpen(false);
  }, [setOpen]);
  const handleStopClick = useCallback(() => {
    close();
    onStopClick(id);
  }, [close, id, onStopClick]);
  // TODO: feature: enable live stream
  // const handleStreamClick = useCallback(() => {
  //   close();
  //   onStreamClick(id);
  // }, [close, id, onStreamClick]);

  return (
    <Card className={styles.root}>
      <CardMedia className={styles.thumbnail} image={Cube} title="Empty Room" />
      <CardContent className={styles.content}>
        <Typography variant="h6" className={styles.title}>
          {title}
        </Typography>
      </CardContent>
      {(onStreamClick || onStopClick) && (
        <Box>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            size="small"
            ref={anchorEl}
            onClick={open}
          >
            <MoreVertIcon />
          </IconButton>
          <Menu anchorEl={anchorEl.current} open={isOpen} onClose={close}>
            <MenuItem onClick={handleStopClick}>
              <Typography variant="body1" className={styles.stopButton}>
                Stop Broadcast
              </Typography>
            </MenuItem>
            {/* <MenuItem onClick={handleStreamClick}>
              <Typography variant="body1">Stream To ...</Typography>
            </MenuItem> */}
          </Menu>
        </Box>
      )}
    </Card>
  );
};

export default RoomCard;
