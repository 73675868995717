import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardHeader, CardContent, Typography } from '@material-ui/core';
import { grey, green } from '@material-ui/core/colors';
import TeamDetailContext from './teamDetailContext';
import { timestampToTime as time, timestampToDate as date } from '../time';
import { sortBy } from 'lodash';

const useStyles = makeStyles(theme => ({
  card: {
    margin: theme.spacing(1),
    marginBottom: 0,
    cursor: 'grab',
    borderLeftWidth: theme.spacing(1.5),
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    '&[data-status="occupied"]': {
      borderLeftColor: grey[500],
    },
    '&[data-status="ready"]': {
      borderLeftColor: green[500],
    },
    minHeight: 140,
    position: 'relative',
    textAlign: 'left',
  },
  playerList: {},
  leader: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  players: {
    textAlign: 'left',
  },
  roomNumber: {
    position: 'absolute',
    right: theme.spacing(0.5),
    top: theme.spacing(-4.5),
    fontSize: 120,
    color: grey[500],
  },
  title: {
    fontWeight: 'bold',
  },
  value: {
    fontWeight: 'normal',
  },
  cardHeader: {
    position: 'relative',
    zIndex: 1,
  },
  cardNumber: {
    position: 'absolute',
    userSelect: 'none',
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
    fontSize: 18,
  },
  button: {
    margin: theme.spacing(1),
    width: '100%',
    boxShadow: 'none',
  },
  cardAction: {
    padding: '0px',
    display: 'flex',
    justifyContent: 'center',
  },
  link: {
    textDecoration: 'none',
  },
}));

const Space = props => {
  const classes = useStyles();
  const { onClick } = useContext(TeamDetailContext);
  const { data, event, type } = props;

  const occupied = Object.keys(event).length > 0;

  const backgroundColor = 'white';

  const handleClick = () => {
    onClick(event);
  };

  const status = occupied ? 'occupied' : 'ready';

  const { startDate, endDate, checkfrontBookingId, host, checkedIn, sku, linkedEvents } = event;

  let members = [];
  if (checkedIn) {
    members = sortBy(
      [
        ...members,
        ...checkedIn.guests,
        ...checkedIn.minors.map(minor => {
          return {
            __typename: minor.__typename,
            id: minor.id,
            firstName: minor.name,
            lastName: '',
            checkedInAt: minor.checkedInAt,
          };
        }),
      ],
      ['checkedInAt', 'firstName', 'lastName'],
    );
  }

  const titleStyle = {
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  };

  const cancelTitleStyle = {
    fontSize: '1.0rem',
    fontWeight: 400,
    lineHeight: 1.334,
    letterSpacing: '0em',
  };

  var title = <div></div>;
  if (linkedEvents && linkedEvents.length > 0) {
    title = (
      <div>
        <Typography style={titleStyle}>{checkfrontBookingId}</Typography>
        {linkedEvents.map((e, index) =>
          e.status === 'cancelled' ? (
            <Typography key={index} color="error" style={cancelTitleStyle}>
              {e.checkfrontBookingId}(cancelled)
            </Typography>
          ) : (
            <Typography key={index} style={titleStyle}>
              {e.checkfrontBookingId}
            </Typography>
          ),
        )}
      </div>
    );
  } else {
    title = (
      <div>
        <Typography style={titleStyle}>{checkfrontBookingId}</Typography>
      </div>
    );
  }

  return (
    <Card className={classes.card} data-status={status} style={{ backgroundColor }}>
      <div>
        <div className={classes.cardNumber}>
          <span>&nbsp;#{data.id}</span>
        </div>
        <CardHeader
          title={title}
          className={classes.cardHeader}
          subheader={
            startDate && endDate ? `${date(startDate)}, ${time(startDate)} - ${time(endDate)}` : ''
          }
        />
        <CardContent onClick={occupied ? handleClick : () => {}}>
          {occupied && (
            <section>
              {sku.toLowerCase().includes('public') === true && (
                <Typography className={classes.title} color="error">
                  Public Sessions
                </Typography>
              )}
              <Typography className={classes.title} color="textSecondary">
                {'Name:'}
              </Typography>
              <Typography className={classes.value} color="textSecondary" gutterBottom>
                {`${host.firstName} ${host.lastName}`}
              </Typography>
              <Typography className={classes.title} color="textSecondary">
                {'Checked In:'}
              </Typography>
              {members.map(member => (
                <Typography
                  key={`guest-${member.id}`}
                  className={classes.value}
                  color="textSecondary"
                >
                  {`${member.firstName} ${member.lastName}`}
                </Typography>
              ))}
            </section>
          )}
        </CardContent>
      </div>
      {/*TODO: feature: enable live stream*/
      /* {occupied && (
        // feature flag: play live
        <CardActions disableSpacing className={classes.cardAction}>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
            style={{ visibility: type === 'ROOM' ? 'visible' : 'hidden' }}
          >
            <Link
              className={classes.link}
              to={location => ({
                ...location,
                pathname: `${location.pathname}/streaming/room`,
                search: `?roomNumber=${data.id}`,
              })}
            >
              <Button
                variant="contained"
                color="secondary"
                size="small"
                aria-label="live"
                className={classes.button}
              >
                <PlayArrow className={classes.extendedIcon} />
                Play
              </Button>
            </Link>
          </Box>
        </CardActions>
      )} */}
    </Card>
  );
};

Space.defaultProps = {
  event: {},
};

Space.propTypes = {
  data: PropTypes.object.isRequired,
  event: PropTypes.object,
  expanded: PropTypes.bool.isRequired,
  onClickExpand: PropTypes.func.isRequired,
};

export default Space;
